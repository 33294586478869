import React from 'react';

import { PortfolioGrid } from '../components';
import { graphql } from 'gatsby';

const IndexPage = ({ data }) => {
  return <PortfolioGrid items={data?.allMarkdownRemark?.edges} />;
};

export default IndexPage;

export const pageQuery = graphql`
  query PatternHomepageQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "pattern" } } }
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            slug
            title
            type
            images {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  height: 400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
